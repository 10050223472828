import React from "react";
const About = () => {
    return (
        <>
            <h1>About <b>Us</b></h1>
            <article className="page-content">
                <p>Welcome to our Morse code translator tool website!</p>

                <p>We are a team of dedicated individuals who are passionate about making communication easier and more accessible. Our goal is to provide a simple and efficient tool for translating Morse code into readable text and vice versa.

                    Our team is comprised of experienced developers, designers, and linguists who have come together to create a tool that is both user-friendly and accurate. We understand the importance of Morse code in certain professions, such as aviation, military, and emergency services, and we strive to make it easy for anyone to use.

                    <br /><br />Our Morse code translator tool is designed to be intuitive and easy to use. Whether you are a beginner or an expert in Morse code, our tool can help you translate messages quickly and efficiently. <br /><br />Simply enter the Morse code or text you want to translate and click the appropriate button. Our tool will then generate the translated text or Morse code for you.

                    We are committed to providing the best possible user experience for our visitors.

                    <br /><br />If you have any questions or feedback, please don't hesitate to contact us. We value your input and are always looking for ways to improve our tool.

                    Thank you for choosing our Morse code translator tool website. We hope that our tool will be a valuable resource for you and help make your communication easier and more efficient.</p>
                <p><br /></p>
                Sincerely, Admin
                <p></p>
            </article>
        </>
    )
}
export default About;