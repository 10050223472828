import React from "react";
const Contact = () => {
    return (
        <>
            <h1>Contact <b>Us</b></h1>
            <article className="page-content">
                <h2 style={{ textAlign: 'center', fontFamily: 'Sans-serif', fontSize: '23px' }}> Wanna connect with us?</h2><br />
                <center>
                    <div className="container" style={{ display: 'flex', justifyContent: 'space-evenly', maxWidth: 'fit-content', margin: 'auto' }}>
                        <div style={{ flex: 1, marginLeft: '16px', maxWidth: '100%' }}><img src="email-ico.png" height={80} width={70} />
                            <p style={{ fontFamily: 'Sans-serif' }}>technicalesson@gmail.com</p>
                        </div>
                    </div>
                    <h3 style={{ fontFamily: 'Sans-serif' }}><b>Thank You!</b></h3>
                    <h3 style={{ fontFamily: 'Sans-serif' }}>We will get back to you soon...</h3>
                </center>
            </article>
        </>
    )
}
export default Contact;