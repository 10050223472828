import React from 'react';
const Alphabet = (props) => {
  const toggleClass = (e) => {
    e.target.parentElement.classList.toggle("active");
  };

  return (
    <>
      <div className="input">
        <h1>Morse Code Chart</h1>
        <h2>Alphabet</h2>
        <table>
          <tbody>
            <tr>
              <td>A</td>
              <td>.-</td>
              <td>B</td>
              <td>-...</td>
              <td>C</td>
              <td>-.-.</td>
              <td>D</td>
              <td>-..</td>
            </tr>
            <tr>
              <td>E</td>
              <td>.</td>
              <td>F</td>
              <td>..-.</td>
              <td>G</td>
              <td>--.</td>
              <td>H</td>
              <td>....</td>
            </tr>
            <tr>
              <td>I</td>
              <td>..</td>
              <td>J</td>
              <td>.---</td>
              <td>K</td>
              <td>-.-</td>
              <td>L</td>
              <td>.-..</td>
            </tr>
            <tr>
              <td>M</td>
              <td>--</td>
              <td>N</td>
              <td>-.</td>
              <td>O</td>
              <td>---</td>
              <td>P</td>
              <td>.--.</td>
            </tr>
            <tr>
              <td>Q</td>
              <td>--.-</td>
              <td>R</td>
              <td>.-.</td>
              <td>S</td>
              <td>...</td>
              <td>T</td>
              <td>-</td>
            </tr>
            <tr>
              <td>U</td>
              <td>..-</td>
              <td>V</td>
              <td>...-</td>
              <td>W</td>
              <td>.--</td>
              <td>X</td>
              <td>-..-</td>
            </tr>
            <tr>
              <td>Y</td>
              <td>-.--</td>
              <td>Z</td>
              <td colSpan={5}>--..</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="number">
        <h2>Number</h2>
        <table>
          <tbody>
            <tr>
              <td>0</td>
              <td>-----</td>
              <td>1</td>
              <td>.----</td>
              <td>2</td>
              <td>..---</td>
              <td>3</td>
              <td>...--</td>
            </tr>
            <tr>
              <td>4</td>
              <td>....-</td>
              <td>5</td>
              <td>.....</td>
              <td>6</td>
              <td>-....</td>
              <td>7</td>
              <td>--...</td>
            </tr>
            <tr>
              <td>8</td>
              <td>---..</td>
              <td>9</td>
              <td colSpan={5}>----.</td>
            </tr>
          </tbody>
        </table>
      </div><div className="number">
        <h2>Punctuation</h2>
        <table>
          <tbody>
            <tr>
              <td>.</td>
              <td>.-.-.-</td>
              <td>,</td>
              <td>--..--</td>
              <td>?</td>
              <td>..--..</td>
            </tr>
            <tr>
              <td>'</td>
              <td>.----.</td>
              <td>!</td>
              <td>-.-.--</td>
              <td>/</td>
              <td>-..-.</td>
            </tr>
            <tr>
              <td>(</td>
              <td>-.--.</td>
              <td>)</td>
              <td>-.--.-</td>
              <td>&amp;</td>
              <td>.-...</td>
            </tr>
            <tr>
              <td>:</td>
              <td>---...</td>
              <td>;</td>
              <td>-.-.-.</td>
              <td>=</td>
              <td>-...-</td>
            </tr>
            <tr>
              <td>+</td>
              <td>.-.-.</td>
              <td>-</td>
              <td>-....-</td>
              <td>_</td>
              <td>..--.-</td>
            </tr>
            <tr>
              <td>"</td>
              <td>.-..-.</td>
              <td>$</td>
              <td>...-..-</td>
              <td>@</td>
              <td>.--.-.</td>
            </tr>
            <tr>
              <td>¿</td>
              <td>..-.-</td>
              <td>¡</td>
              <td colSpan={3}>--...-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="faqs-main">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-container">
          <div className="faq">
            <h3 className="faq-title">
              What is Morse code and how does it work?
            </h3>
            <p className="faq-text">Morse code is a communication system that uses a series of dots, dashes, and spaces to represent letters, numbers, and other characters. It works by transmitting these signals over a communication channel, such as a radio or telegraph wire.
            </p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What is Morse Code Used For?
            </h3>
            <p className="faq-text">Morse Code is use to transmit messages across different media, such as radio waves, flashlights, or flags.
            </p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What is Morse Code Translator?
            </h3>
            <p className="faq-text">A Morse code translator is a tool that allows you to convert text into Morse code and vice versa.</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What are the advantages of using Morse code for communication?
            </h3>
            <p className="faq-text">Morse code has several advantages over other forms of communication, including its simplicity, reliability, and low bandwidth requirements. It can be transmitted and received even in noisy or low-quality communication channels, making it useful in emergency situations or in areas with limited infrastructure.
            </p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              How does a Morse code translator work?
            </h3>
            <p className="faq-text">A Morse code translator is a tool that converts written text into Morse code and vice versa. It works by analyzing the input text and generating a sequence of dots, dashes, and spaces that represent each character in Morse code. This sequence can then be played back as an audio signal or displayed visually as a series of lights or symbols.
            </p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What is the history of Morse code?
            </h3>
            <p className="faq-text">Morse code was invented in the mid-19th century by Samuel Morse and Alfred Vail and was widely used in telegraphy. It played a crucial role in the development of modern communication systems, including radio and television.
            </p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What do 3 Dots Mean in Morse Code?
            </h3>
            <p className="faq-text">The letter "S" is three dots in Morse code: "..."
            </p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What Does SOS Mean in morse code?
            </h3>
            <p className="faq-text">"SOS" is a distress signal in Morse code. It is a sequence of three dots, three dashes, and three dots, which in Morse code represents the letters "SOS". This sequence was adopted internationally as a distress signal in 1905 and is used to indicate that a person or group is in need of immediate assistance.</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              Is Morse code still used by the military?
            </h3>
            <p className="faq-text">Although Morse code is no longer widely used in military communications, it is still taught to some military personnel as a backup communication method. Morse code is also used in emergency situations when other forms of communication may be unavailable or unreliable.</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              Is Morse code still used today?
            </h3>
            <p className="faq-text">While Morse code is no longer a primary form of communication, it is still relevant in certain contexts, such as amateur radio, emergency communications, and military training. It is also an important part of history and technology, having played a crucial role in the development of modern communication systems.</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What is SOS in Morse Code?
            </h3>
            <p className="faq-text">SOS in Morse code is "... --- ..."</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What does "...-" mean in Morse code?
            </h3>
            <p className="faq-text">The letter V is three dots and a dash in Morse code.</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What does "---" mean in Morse code?
            </h3>
            <p className="faq-text">The letter "O" is three dash in Morse code.</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What does ". . . _ _ _ . . ." mean in Morse code?
            </h3>
            <p className="faq-text">The letter "O" is three dash in Morse code.</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              What is the Morse code of "love"?
            </h3>
            <p className="faq-text">".-.. --- ...- ." is the Morse code of "love".</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              How to say "Fuck You" in Morse code?
            </h3>
            <p className="faq-text">"Fuck You" in the Morse code is "..-. ..- -.-. -.- / -.-- --- ..-"</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
          <div className="faq">
            <h3 className="faq-title">
              How to say "I love You" in Morse code?
            </h3>
            <p className="faq-text">"I love You" in the Morse code is "..-. ..- -.-. -.- / -.-- --- ..-"</p>
            <button className="faq-toggle bottom" onClick={(e) => { toggleClass(e) }}></button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Alphabet;