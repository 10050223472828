import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
	const currentYear = new Date().getFullYear();
	return (
		<footer className="footer-distributed">
			<div className="footer-right">
				<Link to="/about">About</Link>
				<Link to="/contact">Contact</Link>
				<Link to="#">Term of Uses</Link>
				<Link to="/privacy-policy">Privacy Policy</Link>
			</div>
			<div className="footer-left">
				<p className="footer-links">
					<p>
						<span>© 2022 – {currentYear} - Morse Code Translator.</span>{" "}
						<span>All rights reserved.</span>
					</p>
				</p>
			</div>
		</footer>
	);
};
export default Footer;
