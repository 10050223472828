import React, { useRef, useState } from 'react';
import { useSpeechSynthesis } from "react-speech-kit";

const Input = (props) => {
  const [copy, setCopy] = useState('Copy');
  const inputElement = useRef();
  const { speak, cancel, speaking } = useSpeechSynthesis();

  const copyToClipboard = (e) => {
    if (props.value) {
      inputElement.current.select();
      document.execCommand('copy');
      inputElement.current.focus();
      setCopy('Copied!');
    }
  };

  const togglePlay = () => {
    if (speaking) {
      cancel();
    } else {
      if (props.value) {
        speak({ text: props.value });
      }
    }
  }

  const handleChange = (event) => {
    if (copy === 'Copied!') {
      setCopy('Copy');
    }
    props.setValue(event.target.value);
  }

  return (
    <div className="input">
      <h2 className="centerAlign">Text</h2>
      <button class="small button" onClick={copyToClipboard}>{copy}</button>
      <button class="small button margin-left" onClick={togglePlay}>{speaking ? "Pause" : "Play"}</button>
      <textarea
        value={props.value}
        onChange={handleChange}
        placeholder="Type your text here"
        ref={inputElement}
      />
    </div>
  );
}

export default Input;