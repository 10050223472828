import React, { useState, useRef } from 'react';
const morse = require('morse-decoder');

const Output = (props) => {
  const [play, setPlay] = useState(false);
  const [copy, setCopy] = useState('Copy');
  const inputElement = useRef();

  const playMorse = () => {
    if (props.value) {
      var audio;
      if (!play) {
        audio = morse.audio(props.value, {
          unit: 0.1,
          fwUnit: 0.1,
          oscillator: {
            type: 'sine', // sine, square, sawtooth, triangle
            frequency: 500,  // value in hertz
            onended: function () {
              setPlay(false);
            }
          }
        });
        audio.play();
      } else {
        console.log('here');
        audio.stop();
      }

      setPlay(true);
    }
  }

  const copyToClipboard = (e) => {
    if (props.value) {
      inputElement.current.select();
      document.execCommand('copy');
      inputElement.current.focus();
      setCopy('Copied!');
    }
  };

  const downloadWave = () => {
    if (props.value) {
      const audio = morse.audio(props.value);
      audio.exportWave();
    }
  }

  const handleChange = (event) => {
    var value = "";
    if (event.target.value) {
      value = morse.decode(event.target.value);
    }
    props.setValue(value);
  }
  return (
    <div className="output">
      <h2 className="centerAlign">Morse Code</h2>
      <button class="small button" onClick={copyToClipboard}>{copy}</button>
      <button class="small button margin-left" onClick={playMorse}>{play ? "Pause" : "Play"}</button>
      <button class="small button margin-left" onClick={downloadWave}>Download Wave</button>
      <textarea
        value={morse.encode(props.value)}
        onChange={handleChange}
        placeholder="Type your morse code here"
        ref={inputElement}
      />
    </div >
  );
}

export default Output;