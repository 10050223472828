import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
export default class Main extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="App">
                        {this.props.children}
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}