import React from "react";
import { Link } from "react-router-dom";
const Header = () => {
	return (
		<nav>
			<input type="checkbox" id="nav-toggle" />
			<Link to="/">
				<div className="logo">
					MORSE<strong>CODE</strong>
				</div>
			</Link>
			<ul className="links">
				<li>
					<Link to="/">Home</Link>
				</li>
				<Link to="https://invoicegenerator.morsecodeinfo.com" target="_blank">
					Invoice Generator
					<span className="new-label">New</span>
				</Link>
			</ul>
			<label htmlFor="nav-toggle" className="icon-burger">
				<div className="line" />
				<div className="line" />
				<div className="line" />
			</label>
		</nav>
	);
};
export default Header;
