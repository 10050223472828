import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import MainLayout from './components/layout/Main';
import Main from "./components/home/Main";
import About from "./components/about";
import Contact from "./components/contact";
import PrivacyPolicy from "./components/privacy-policy";

const App = () => {
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/:lang" element={<Main />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </MainLayout>
    </Router >
  );
}

export default App;