import React, { useState } from 'react';
import Input from './input';
import Output from './output';
import Alphabet from './alphabet';
const Main = () => {
    const [value, setValue] = useState('');
    const nextProps = {
        value,
        setValue,
    };

    return (
        <>
            <h1>Morse Code Translator</h1>
            <Input {...nextProps} />
            <Output {...nextProps} />
            <Alphabet />
        </>
    );
}

export default Main;